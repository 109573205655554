import { axios } from '@/utils/request'

const api = {
   selectPageLog: 'charging/operation/log/selectPage', // 查询日志
}
export default api

// 查询日志
export const selectPageLogApi = (params) => {
    return axios({
        url: api.selectPageLog,
        method: 'post',
        params
    })
}