<!-- 日志模块 -->
<template>
  <div class="warp">
    <el-card class="warp-content">
      <!-- 搜素区域 -->
      <div class="page-select">
        <el-row style="width: 100%; margin: 0 0 15px 0">
          <el-col :span="24" style="text-align: right">
            <el-input
              placeholder="请输入用户名称"
              class="search-input"
              prefix-icon="search"
              size="small"
              @input="searchInput"
              v-model="userInput"
              clearable
            ></el-input>
            <el-button type="primary" size="small" @click="searchInput"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </div>

      <!-- 表格区域 -->
      <div class="page-container">
        <el-table
          ref="tableRef"
          :data="tableData"
          style="width: 100%"
          max-height="630px"
          :header-cell-style="{ background: '#F2F4F8' }"
          @selection-change="handleSelectionChange"
        >
          <!--<el-table-column type="selection" align="center" width="80" />-->
          <el-table-column
            v-for="(item, index) in tableColumnData"
            :key="index"
            :prop="item.prop"
            :label="item.label"
            align="center"
          >
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页区域 -->
      <div class="page-foot">
        <page-nation
          :total="total"
          @pageChange="handleSizeChange"
          @currentChange="handleCurrentChange"
        ></page-nation>
      </div>
    </el-card>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted, getCurrentInstance } from "vue-demi";
import pageNation from "@/components/base/pageNation.vue";
import { requestApi } from "@/utils/requestApi";
import { debounces } from "@/common/utils";
import { selectPageLogApi } from "@/api/logManagement/logManagement";
export default {
  name: "logManagement",
  components: { pageNation },
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      searchValue: null, //搜索用户名
      tableData: [], // 表格数据
      total: 0,
      pageSize: 10,
      currentPage: 1,
      userInfo: null,
      userLevel: 0, //用户等级
      selection: [],
      tableColumnData: [
        {
          prop: "operMerchantAccount",
          label: "用户",
        },
        {
          prop: "title",
          label: "操作",
        },
        // {
        //   prop: "operRequestParam",
        //   label: "日志内容",
        // },
        {
          prop: "operIp",
          label: "终端类型/IP",
        },
        {
          prop: "updateTime",
          label: "操作时间",
        },
      ],
      userInput: "",
    });
    onMounted(() => {
      proxy.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      proxy.$nextTick(() => {
        proxy.userLevel = proxy.userInfo.chargingMerchant.level;
      });
      console.log(proxy.userInfo, "proxy.userInfo");
      methods.getAll();
    });
    // 节流搜索按钮
    const searchInput = debounces(() => {
      methods.getAll();
    }, 500);
    const methods = {
      // 查询日志
      async getAll() {
        await requestApi(
          () => {
            const params = {
              current: data.currentPage,
              size: data.pageSize,
              merchantId: data.userInfo.merchantId,
              // createUser: data.userInput,
              operMerchantAccount:data.userInput,
            };
            return selectPageLogApi(params);
          },
          (res) => {
            proxy.tableData = res.data.records;
            proxy.total = res.data.total;
            proxy.currentPage = res.data.current;
            proxy.pageSize = res.data.size;
          }
        );
      },
      // 表格分页 改变每页数量时触发
      handleSizeChange(val) {
        proxy.pageSize = val;
        methods.getAll();
      },
      // 表格分页 改变页数时触发
      handleCurrentChange(val) {
        proxy.currentPage = val;
        methods.getAll();
      },
    };
    return {
      ...toRefs(data),
      searchInput,
      ...methods,
    };
  },
};
</script>
<style lang="less" scoped>
.warp {
  height: 100%;
  .warp-content {
    height: 100%;
  }
}
.page-select {
  width: 100%;
  margin: 0 auto;
}
.page-container {
  width: 100%;
  //margin-top: -28px;
  .imgeTou {
    width: 35px;
    height: 35px;
  }
}
.page-foot {
  width: 100%;
  margin-top: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .my-btn {
    height: 28px;
  }
}
.search-input {
  width: 202px;
  margin-right: 4px;
  margin-top: 6px;
}
</style>
